export default {
  ACTION: 'Ação',
  ACTIVE: 'Ativo',
  ADD_BOOK: 'Adicionar Livros',
  ADD_COVER_IMAGE: 'Adicionar imagem de capa',
  ADD: 'Adicionar',
  ADDRESS_CITY: 'Cidade',
  ADDRESS_COMPLEMENT: 'Complemento',
  ADDRESS_NEIGHBORHOOD: 'Bairro',
  ADDRESS_NUMBER: 'N°',
  ADDRESS_STATE: 'Estado',
  ADDRESS_STREET: 'Rua',
  ADDRESS_ZIPCODE: 'CEP',
  ADDRESS: 'Endereço',
  ADMIN_EMAIL_ALREADY_EXISTS: 'Esse email já é usado por um administrador.',
  ADMIN: 'Administrador',
  AFFILIATED_COMMISSION: 'Comissão Afiliado',
  ALL_PRODUCTS_SHOULD_HAVE_IMAGE: 'Todos os produtos devem ter imagem de capa.',
  ALL: 'Todos',
  ALPHANUMERIC_CODE: 'Código alfanumérico',
  ANALYTICS_WIDGETS: 'Widgets',
  API_INTEGRATION: 'Integração (API)',
  APPLIED_COUPON: 'Cupom aplicado',
  ATTRIBUTE_ACTIVE: 'Ativo',
  ATTRIBUTE_ALREADY_EXISTS: 'O atributo já existe no produto.',
  ATTRIBUTE_FORMAT: 'Formato',
  ATTRIBUTE_HEIGHT: 'Altura',
  ATTRIBUTE_LENGTH: 'Comprimento',
  ATTRIBUTE_PAGES: 'Páginas',
  ATTRIBUTE_SAVED: 'Atributo salvo com sucesso.',
  ATTRIBUTE_SPINE: 'Lombada',
  ATTRIBUTE_WIDTH: 'Largura',
  ATTRIBUTE: 'Atributo',
  ATTRIBUTES: 'Atributos',
  AUTHOR_NOT_FOUND: 'Autor não encontrado.',
  AUTHOR_OR_AUTHORS: 'Autor(es)',
  AUTHOR_SAVED: 'Autor salvo com sucesso.',
  AUTHOR: 'Autor',
  AUTHORS: 'Autores',
  AVERAGE_TICKET: 'Ticket médio',
  BACK: 'Voltar',
  BANNER_ALREADY_EXISTS_IN_POSITION: 'Já existe um banner cadastrado nessa posição.',
  BANNER_SAVED: 'Banner salvo com sucesso.',
  BANNER: 'Banner',
  BANNERS: 'Banners',
  BAR_CODE_ABBV: 'Cód. de barras',
  BAR_CODE_COLUMN: 'Coluna do código do produto',
  BARCODE_ALREADY_EXISTS_IN_STORE: 'O código informado já existe no centro selecionado.',
  BARCODE: 'Código do produto',
  BASE_URL: 'Base URL',
  BIOGRAPHY: 'Biografia',
  BIRTHDATE_REQUIRED: 'Data de nascimento não informada',
  BIRTHDATE: 'Data de nascimento',
  BOLETO: 'Boleto',
  BOOKBINDING: 'Acabamento',
  BRAND_IS_REQUIRED: 'O nome da marca é obrigatório.',
  BRAND_MAX_LENGTH_IS_255: 'O nome da marca não pode ter mais do que 255 caracteres.',
  BRAND_NOT_FOUND: 'Editora não encontrada',
  BRAND_NOT_FOUND: 'Marca não encontrada.',
  BRAND_SAVED: 'Editora salva com sucesso.',
  BRAND_SAVED: 'Marca salva com sucesso.',
  BRAND: 'Marca',
  BRANDS_DESCRIPTION: 'As editoras cadastradas vão ser listadas dentro de um carousel na página principal da loja',
  BRANDS: 'Editoras',
  CANCEL_ORDER: 'Cancelar pedido',
  CANCEL: 'Cancelar',
  CANNOT_CANCEL_THIS_ORDER_NF: 'Não é possível cancelar esse pedido, a Nota Fiscal já foi emitida.',
  CANNOT_CANCEL_THIS_ORDER: 'Não é possível cancelar esse pedido.',
  CANNOT_REFUND_THIS_ORDER: 'Não foi possível reembolsar esse pedido.',
  CANNOT_SET_THIS_STATUS: 'Não é possível alterar o status desse pedido para o status selecionado.',
  CARD_PAYMENT_PLATFORM: 'Plataforma de pagamento para cartão de crédito',
  CASH: 'Dinheiro',
  CATALOGUE_MAX_ITEMS_EXCEEDED: 'A vitrine deve conter no máximo 20 itens.',
  CATALOGUE_POSITIONS_TIP: 'Arraste as vitrines na tabela para definir a posição.',
  CATALOGUE_PRODUCTS_BARCODE_REQUIRED: 'Não foi possível identificar o código de barras de um ou mais produtos.',
  CATALOGUE_PRODUCTS_REQUIRED: 'Selecione pelo menos um item.',
  CATALOGUE_SAVED: 'Vitrine salva com sucesso.',
  CATALOGUE_SPREADSHEET_MAX_ITEMS_EXCEEDED: 'A planilha deve conter no máximo 20 itens.',
  CATALOGUES_DESCRIPTION: 'As vitrines cadastradas vão ser listadas na página principal da loja',
  CATALOGUES: 'Vitrines',
  CATEGORIES_DESCRIPTION: 'As categorias cadastradas vão ser listadas na página principal da loja',
  CATEGORIES: 'Categorias',
  CATEGORY_NOT_FOUND: 'Categoria não encontrada',
  CATEGORY_OR_CATEGORIES: 'Categoria(s)',
  CATEGORY_SAVED: 'Categoria salva com sucesso.',
  CATEGORY: 'Categoria',
  CELL_PHONE: 'Celular',
  CHANGE_STATUS: 'Alterar status',
  CHANGES_HISTORY: 'Histórico de alterações',
  CITY_STATE: 'Cidade/UF',
  CLEAR: 'Limpar',
  CLIENT_REQUIRED: 'Sua sessão expirou.',
  CNPJ_REQUIRED: 'CNPJ não informado',
  CODE_ALREADY_EXISTS: 'Esse código já está cadastrado.',
  CODE_LENGTH_SUGGESTION: 'Para %qty% cupons numéricos, utilize no mínimo %num% caracteres. Para cupons alfanuméricos, %alpha%.',
  CODE_LENGTH_TOO_SHORT: 'Quantidade de caracteres insuficiente para a quantidade de cupons.',
  CODE_LENGTH: 'Caracteres',
  CODE_MAX_LENGTH_IS_255: 'O código do produto não pode ter mais do que 255 caracteres.',
  CODE_REQUIRED: 'O código é obrigatório',
  CODE: 'Código',
  COLOR: 'Cor',
  COMPANY_NAME: 'Razão social',
  CONFIRM_CANCEL_ORDER: 'Tem certeza que deseja cancelar esse pedido?',
  CONFIRM_DELETE: 'Tem certeza que deseja apagar esse registro?',
  CONFIRM_PRIORITY_FACTOR: 'Tem certeza que deseja atualizar a prioridade de estoque?',
  CONFIRM_RESEND_ORDER: 'Enviar novamente o pedido para o WinBooks?',
  CONFIRM: 'Confirmar',
  CONNECT: 'Conectar',
  CONNECTED_TO_PAGSEGURO: 'Conectado ao PagSeguro',
  CONTACT_MAIL: 'Email de contato',
  CONTINUE: 'Continuar',
  COPIED_TO_CLIPBOARD: 'Copiado para a área de transferência',
  COPY: 'Copiar',
  COUPON_SAVED: 'Cupom salvo com sucesso',
  COUPON: 'Cupom',
  COUPONS_GENERATED: 'Cupons gerados com sucesso.',
  COUPONS_GENERATOR: 'Gerador de Cupons',
  COUPONS: 'Cupons',
  COVER_REQUIRED: 'A imagem de capa é obrigatória.',
  COVER_URL: 'Capa (URL)',
  CPF_ALREADY_EXISTS: 'Esse CPF já está cadastrado.',
  CPF_CNPJ: 'CPF/CNPJ',
  CPF_REQUIRED: 'CPF não informado',
  CREATE_DISCOUNT: 'Criar desconto',
  CREATE_PRODUCT: 'Novo produto',
  CREDIT_CARD: 'Cartão de crédito',
  CUSTOMER_DATA: 'Dados do cliente',
  CUSTOMER_EMAIL: 'E-mail do Cliente',
  CUSTOMER_NAME: 'Cliente',
  DATE: 'Data',
  DAYS: 'Dia(s)',
  DESCRIPTION: 'Descrição',
  DISCOUNT_CREATED_ERROR: 'Já existe uma regra ativa para esta Editora/Autor.',
  DISCOUNT_CREATED: 'Desconto aplicado com sucesso.',
  DISCOUNT_DELETED: 'Desconto removido com sucesso.',
  DISCOUNT_PERCENTAGE: '% de desconto',
  DISCOUNT_TYPE: 'Tipo de desconto',
  DISCOUNT: 'Desconto',
  DISTRIBUTION_CENTER_ABBRV: 'CD',
  DISTRIBUTION_CENTER: 'Centro de Distribuição',
  DISTRIBUTION_CENTERS: 'Centros de Distribuição',
  DRAG_IMAGE: 'Arraste uma imagem ou clique no botão abaixo',
  DROP_FILE: 'Solte a imagem aqui',
  EDIT_CATALOGUE: 'Alterar vitrine',
  EDIT_POSITIONS: 'Editar posições',
  EDIT_PRODUCT: 'Editar Produto',
  EDIT: 'Editar',
  EDITED_VALUE: 'Valor editado',
  EMAIL_ALREADY_EXISTS: 'Já existe um usuário cadastrado com este email.',
  EMAIL: 'E-mail',
  EMPTY_BAR_CODE: 'Código do produto vazio.',
  EMPTY_PERCENT: 'A coluna de porcentagem está vazia.',
  EXAMPLE: 'Exemplo',
  EXPIRATION_DATE: 'Data de expiração',
  EXPIRED_AT: 'Expirado em',
  EXPORT: 'Exportar',
  FAIL_TO_DELETE_STORE: 'Ocorreu um erro ao deletar o centro de distribuição.',
  FAILED_TO_CANCEL_TRANSACTION_PLEASE_TRY_AGAIN: 'Falha ao cancelar transação. Por favor, tente novamente.',
  FEMALE: 'Feminino',
  FILE: 'Arquivo',
  FILL_COUPON_CODE_TO_GENERATE_COUPONS: 'Preencha o código do cupom para continuar',
  FIRST_ROW_SHOULD_BE_BAR_CODE: 'Primeira linha da planilha deve ser esta: BAR_CODE',
  FORGOT_PASSWORD: 'Esqueci a senha',
  FRANCHISEES: 'Franqueados',
  FRANCHISING: 'Franquia',
  FREE_SHIPPING_MIN_VALUE: 'Valor mínimo do pedido',
  FREE_SHIPPING: 'Frete Grátis',
  FREE: 'Grátis',
  FROM: 'De',
  FULL_NAME: 'Nome completo',
  GENDER: 'Sexo',
  GENERAL_INFORMATION_SAVED: 'Informações editadas com sucesso.',
  GENERAL_INFORMATION: 'Informações gerais',
  GENERATE: 'Gerar',
  GENERATED_REPORTS: 'Relatórios gerados',
  GENERATED_SECRET_KEY_ADVICE: 'Essa é a sua chave privada. Por motivos de segurança, só será exibida nessa página. Em caso de perda será necessário criar novamente.',
  GOOGLE_ANALYTCS: 'Google Analytics',
  GOOGLE_PROPERTY_VERIFICATION: 'Verificação de propriedade do Google Search Console',
  GOOGLE_TAGMANAGER: 'Google Tag Manager',
  HANDLING_ADDITIONAL_DAYS: 'Dias para o Serviço',
  HEIGHT_CM: 'Altura (cm)',
  HOME_ICON_descriPTION_PLACEHOLDER: 'Exemplo: Consulte os Termos',
  HOME_ICON_LABEL_PLACEHOLDER: 'Exemplo: Entrega Grátis',
  HOME_ICONS: 'Seção de Ícones',
  HOME: 'Home',
  HOURS: 'Hora(s)',
  ICON_ALREADY_EXISTS_IN_POSITION: 'Já existe um ícone nessa posição.',
  ICON: 'Ícone',
  ICONS_DESCRIPTION: 'Faixa de ícones da página inicial.',
  ICONS: 'Ícones',
  IMAGE_ADDED_SUCCESSFULLY: 'Imagem atualizada!',
  IMAGE_MAX_SIZE_IS_10MB: 'A imagem é muito grande. O tamanho maximo é de 10MB.',
  IMAGE_REQUIRED: 'A imagem é obrigatória.',
  IMAGE_SECTION: 'Seções de imagens',
  IMAGE: 'Imagem',
  IMPORT_CPF: 'Importar lista de CPF',
  IMPORT_FILE: 'Importar arquivo',
  IMPORT_PRICE_RULES_ERROR: 'Falha ao importar os descontos, linha <strong>{line}</strong>:',
  IMPORT_PRICE_RULES_SUCCESS: 'Os descontos foram aplicados com sucesso. <br/> Total de descontos: <strong>{total}</strong>. <br/> Produtos não encontrados: <strong>{ignored}</strong>',
  IMPORT_PRICE_RULES: 'Importar descontos',
  IMPORT_PRODUCTS_ERROR: 'Falha ao importar os produtos, linha <strong>{line}</strong>:',
  IMPORT_PRODUCTS_SUCCESS: 'Os descontos foram aplicados com sucesso. <br/> Total de produtos importados: <strong>{total}</strong>.',
  IMPORT_PRODUCTS: 'Importar produtos',
  IMPORT: 'Importar',
  IN_STOCK_ITEMS: 'Itens em estoque',
  INACTIVE_PRODUCT: 'Produto inativo',
  INACTIVE_URL: 'Por favor, verifique se o link está ativo',
  INACTIVE: 'Inativo',
  INSERT_HTML_OR_VERIFICATION_CODE: 'Insira abaixo a tag HTML ou código de verificação',
  INSTALLMENT_INTEREST_FROM: 'Primeira parcela com juros',
  INSTALLMENT_MIN_VALUE: 'Valor mínimo da parcela',
  INTEREST_RATE: 'Taxa de juros',
  INVALID_BAR_CODE: 'Código do produto inválido.',
  INVALID_BIRTHDATE: 'A data de nascimento informada é inválida.',
  INVALID_CALLBACK_URL: 'URL de callback inválida.',
  INVALID_CELL_PHONE: 'O número de celular é inválido. Lembre-se de incluir o DDD.',
  INVALID_CNPJ_FORMAT: 'O formato do CNPJ é inválido.',
  INVALID_CNPJ: 'CNPJ inválido.',
  INVALID_COMPLEMENT_LENGTH: 'O complemento deve ter no máximo 40 caracteres.',
  INVALID_COVER_FILE: 'Arquivo de capa inválido.',
  INVALID_COVER_FORMAT: 'Formato de capa inválido.',
  INVALID_COVER_URL: 'A URL  da capa é inválida.',
  INVALID_CPF_FORMAT: 'Formato do CPF inválido.',
  INVALID_CPF: 'CPF inválido',
  INVALID_EMAIL: 'Email inválido.',
  INVALID_EXPIRE_AT_DATE: 'A data de expiração inserida é inválida.',
  INVALID_FACEBOOK_PIXEL: 'O código de identifação do Facebook Pixel é inválido',
  INVALID_GOOGLE_ANALYTICS: 'O código de acompanhamento do Google Analytics é inválido',
  INVALID_HEIGHT_FORMAT: 'A altura do produto é inválida.',
  INVALID_IMAGE: 'O arquivo precisa ser uma imagem',
  INVALID_LENGTH_FORMAT: 'O comprimenro informado é inválido',
  INVALID_PERCENT: 'Porcentagem de desconto inválida.',
  INVALID_PHONE: 'Telefone inválido. Lembre-se de incluir o DDD.',
  INVALID_PRICE_FORMAT: 'O preço do produto é inválido',
  INVALID_PRICE_VALUE: 'Por favor, insira um preço válido para o produto.',
  INVALID_STOCK_VALUE: 'O estoque informado é inválido',
  INVALID_TAGMANAGER: 'O código do Google Tag Manager é inválido',
  INVALID_UF: 'Selecione um estado válido.',
  INVALID_WEIGHT_FORMAT: 'O peso informado é inválido',
  INVALID_WIDTH_FORMAT: 'A largura informada é inválida',
  INVALID_ZIPCODE: 'CEP inválido.',
  INVOICE_NUMBER_ALREADY_ISSUED: 'Não foi possível cancelar o pedido, pois a nota fiscal já foi emitida.',
  INVOICE_NUMBER: 'Nota Fiscal',
  ISBN: 'ISBN',
  JURIDICAL_PERSON: 'Pessoa jurídica',
  KEYWORDS: 'Palavras-chave',
  LABEL_CNPJ: 'CNPJ',
  LABEL_CPF: 'CPF',
  LABEL_CREATE: 'Cadastrar',
  LABEL_INSERT: 'Inserir',
  LANDLINE: 'Telefone fixo',
  LEAVE_HOST_FIELD_EMPTY: 'Deixe em branco para usar o servidor padrão.',
  LINK_YOUR_PAGSEGURO_ACCOUNT_TO_UPGRADE: 'Vincule sua conta do PagSeguro para utilização da nova versão da API',
  LINK: 'Link',
  LIST: 'Lista',
  LOAD_MORE: 'Carregar mais',
  LOADING_THREE_DOTS: 'Carregando...',
  LOADING: 'Carregando',
  LOCAL_PICKUP: 'Retirada em Loja',
  LOGIN: 'Login',
  LOGO: 'Logo',
  MAIL_HOST: 'Host',
  MAIL_PORT: 'Porta',
  MAIN_ORDER_NUMBER: 'Ordem de Compra',
  MALE: 'Masculino',
  MARK_LOCAL_PICKUP_AVAILABLE: 'Marcar retirada em loja disponível',
  MAX_CATALOGUE_PRODUCTS_REACHED: 'Remova um item da vitrine para adicionar mais produtos.',
  MAX_INSTALLMENTS: 'Qtd. máxima de parcelas',
  MENU: 'Menu',
  MIN_VALUE: 'Valor mínimo',
  MINUTES: 'Minuto(s)',
  NAME_IS_REQUIRED: 'O nome do produto é obrigatório.',
  NAME_MAX_LENGTH_IS_255: 'O nome do produto não pode ter mais do que 255 caracteres.',
  NAME_MUST_BE_FULL_NAME: 'Insira o nome completo.',
  NAME: 'Nome',
  NATURAL_PERSON: 'Pessoa física',
  NEW_ATTRIBUTE: 'Novo Atributo',
  NEW_AUTHOR: 'Novo autor',
  NEW_BRAND: 'Nova marca',
  NEW_CATALOGUE: 'Nova vitrine',
  NEW_CATEGORY: 'Nova categoria',
  NEW_SECRET_KEY_ADVICE: 'A chave atual será invalidada. Tem certeza que deseja criar uma nova chave?',
  NO_FURTHER_REFUND_ALLOWED: 'Não é possível realizar mais reembolsos.',
  NO_ITEMS_SELECTED: 'Nenhum item selecionado',
  NO_OPTION: 'Nenhuma opção',
  NO_RECORDS_FOUND: 'Nenhum registro encontrado',
  NO_STORES_FOUND: 'Nenhum centro de distribuição cadastrado.',
  NOTE: 'Observação',
  NUMERIC_CODE: 'Código numérico',
  ONE_ITEM_SELECTED: '1 item selecionado',
  ONLINE_DEBIT: 'Débito online',
  OPENING_HOURS: 'Horário de funcionamento',
  ORDER_CANCEL_SUCCESS: 'O pedido foi cancelado com sucesso.',
  ORDER_DATA: 'Dados do pedido',
  ORDER_HAS_NO_REGISTERED_CHARGE_YET: 'Não é possível cancelar esse pedido no momento, não existe o registro de cobrança.',
  ORDER_ITEMS: 'Itens do pedido',
  ORDER_NOT_FOUND: 'Pedido não encontrado.',
  ORDER_NUMBER: 'Pedido',
  ORDER_RESUME: 'Resumo da ordem de compra',
  ORDER_STATUS_CHANGE_SUCCESS: 'O status do pedido foi alterado com sucesso.',
  ORDER_UPDATED_SUCCESSFULLY: 'Pedido atualizado com sucesso.',
  ORDER_USAGE_LIMIT: 'Limite de pedidos',
  ORDER_WILL_BE_SENT: 'O pedido será enviado novamente',
  ORDER: 'Pedido',
  ORDERS_RECEIVED: 'Total de pedidos',
  ORDERS: 'Pedidos',
  ORIGIN: 'Origem',
  ORIGINAL_VALUE: 'Valor original',
  OTHER: 'Outro',
  OUT_OF_CATALOGUE: 'Fora de catálogo',
  OUT_OF_STOCK: 'Sem estoque',
  PAGSEGURO_CANCEL_ERROR: 'Ocorreu um erro ao cancelar o pedido no PagSeguro.',
  PAGSEGURO_CONNECTED_SUCCESSFULLY: 'Conectado com sucesso ao PagSeguro.',
  PAGSEGURO: 'PagSeguro',
  PARTIAL_REFUND_NOT_AVAILABLE: 'Reembolso parcial não disponível.',
  PASSEGURO_AUTH_CODE_IS_REQUIRED: 'O Código de autorização do PagSeguro é obrigatório',
  PASSWORD: 'Senha',
  PAYMENT_CONFIRMED_AT: 'Pagamento confirmado em',
  PAYMENT_METHOD: 'Método de pagamento',
  PAYMENT: 'Pagamento',
  PERCENT_COLUMN: 'Coluna da porcentagem',
  PERCENT_EXCEEDED: 'A porcentagem deve ser menor que 100%',
  PERCENT: 'Porcentagem',
  PERMISSION_DENIED: 'Você não tem permissão para realizar essa ação.',
  PHONE: 'Telefone',
  PIX_CODE_VALIDITY: 'PIX - Validade do QRCode',
  PIX_MUST_LAST_AT_LEAST_ONE_MINUTE: 'O QRCode do PIX deve durar pelo menos 1 minuto',
  PIX_MUST_NOT_LAST_LONGER_THAN_ONE_MONTH: 'O QRCode do PIX não deve durar mais do que 30 dias',
  PIXEL_FACEBOOK: 'Pixel Facebook',
  POSITION_REQUIRED: 'A posição é obrigatória.',
  POSITION_UNAVAILABLE_ERROR: 'A posição escolhida já está sendo utilizada por outra seção!',
  POSITION: 'Posição',
  PRICE_IS_REQUIRED: 'O preço do produto é obrigatório',
  PRICE: 'Preço',
  PRIORITY_FACTOR_TIP: 'Arraste os centros na tabela para definir a prioridade.',
  PRIORITY_FACTOR: 'Fator de Prioridade',
  PRIORITY_ORDER_SAVED: 'Prioridade de estoque salva com sucesso.',
  PRODUCT_COVER: 'Capa do produto',
  PRODUCT_DELETED: 'Produto removido com sucesso.',
  PRODUCT_NOT_FOUND: 'Produto não encontrado.',
  PRODUCT_SAVED: 'Produto salvo com suceso.',
  PRODUCT: 'Produto',
  PRODUCTS_IMPORTED_SUCCESSFULLY: 'Produtos importados com sucesso.',
  PRODUCTS: 'Produtos',
  PUBLIC_KEY: 'Chave pública',
  PUBLISH_DATE: 'Data de publicação',
  PV: 'PV',
  QUANTITY_STOCK: 'Qtd. estoque',
  QUANTITY: 'Quantidade',
  RECEIVER: 'Destinatário',
  REDE_REFUND_REQUESTED: 'O cancelamento foi solicitado na Rede e o processamento deve ocorrer no próximo dia útil.',
  REDE: 'Rede',
  REFUND_AMOUNT: 'Reembolsado',
  REFUND_ID_NOT_FOUND: 'Reembolso não encontrado.',
  REFUND_NOT_ALLOWED_CHARGEBACK_REQUESTED: 'Reembolso não permitido. Chargeback solicitado.',
  REFUND_NOT_ALLOWED: 'Reembolso não permitido.',
  REFUND_NOT_FOUND: 'Reembolso não encontrado.',
  REFUND_ORDER_ADVICE: 'Esse pedido já foi pago e o valor será estornado para o cliente.',
  REGISTER_AT: 'Registrado em',
  REMOVE: 'Remover',
  REQUEST_FAILED_CONTACT_REDE: 'Falha na requisição. Entre em contato com a Rede.',
  ROOT_CATEGORY: 'Categoria Raiz',
  SALE_BY_AFFILIATE: 'Venda por Afiliado',
  SALES_REPORT: 'Relatório de Vendas',
  SAVE_HOME_ICON_SUCCESS: 'Ícone salvo com sucesso.',
  SAVE: 'Salvar',
  SCHOOL: 'Escola',
  SEARCH_BY: 'Buscar por',
  SEARCH_OR_CREATE_BRAND: 'Buscar uma marca',
  SEARCH_PRODUCTS: 'Buscar produtos',
  SEARCH_THREE_DOTS: 'Buscar...',
  SEARCH: 'Buscar',
  SEARCHING_THREE_DOTS: 'Buscando...',
  SECRET_KEY: 'Chave privada',
  SECTION_SAVED: 'Seção salva com sucesso.',
  SEE_REPORTS: 'Ver relatórios',
  SELECT_A_BRAND: 'Selecione uma editora',
  SELECT_A_BRAND: 'Selecione uma marca.',
  SELECT_A_CATEGORY: 'Selecione uma categoria',
  SELECT_A_ROOT_CATEGORY: 'Para cadastrar uma subcategoria, selecione uma categoria raiz.',
  SELECT_AN_ICON: 'Selecione um ícone',
  SELECT_AT_LEAST_ONE_PRODUCT: 'Selecione pelo menos um item.',
  SELECT_BOOKS: 'Selecionar livros',
  SELECT_FILE: 'Selecionar arquivo',
  SELECT_SPREADSHEET_COLUMNS: 'Selecione as colunas da planilha',
  SELECT_THE_BOOKS: 'Selecione os livros',
  SELECT: 'Selecione',
  SENT_AT: 'Enviado em',
  SETTINGS: 'Configurações',
  SHIPPING_CODE: 'Código de rastreamento',
  SHIPPING_DATA: 'Dados da entrega',
  SHIPPING_METHOD_IS_REQUIRED: 'Selecione um método de entrega.',
  SHIPPING_METHOD: 'Método de envio',
  SHIPPING_METHODS: 'Métodos de Entrega',
  SHIPPING: 'Frete',
  SHOW_QUANTITY_IN_STOCK: 'Mostrar Qtd. em Estoque?',
  SLIDE: 'Slide',
  SLUG: 'Slug',
  SPREADSHEET: 'Planilha',
  STATE_REGISTRY_NUMBER: 'Inscrição estadual',
  STATUS_CANCELED: 'Cancelado',
  STATUS_COMPLETED: 'Finalizado',
  STATUS_CONFIRMED_PAYMENT: 'Pagamento Confirmado',
  STATUS_IN_DISPUTE: 'Em Disputa',
  STATUS_PENDING_PAYMENT: 'Aguardando Pagamento',
  STATUS_PROCESSING: 'Processando',
  STATUS_READY_FOR_PICKUP: 'Pronto para retirada',
  STATUS_REFUNDED: 'Reembolsado',
  STATUS_SHIPPED: 'Enviado',
  STATUS: 'Status',
  STOCK_IS_REQUIRED: 'O campo Quantidade em Estoque é obrigatório',
  STOCK: 'Estoque',
  STORE_NOT_FOUND: 'Centro de Distribuição não encontrado.',
  STORE_ORDER_STATUS_WARNING: 'Confirmar o pagamento ou cancelar o pedido terá efeito nos outros pedidos da ordem de compra. Deseja alterar o status de todos os pedidos?',
  STORE_SAVED: 'Centro de Distribuição salvo com sucesso.',
  SUBCATEGORY: 'Subcategoria',
  SUBTITLE: 'Subtítulo',
  SUBTOTAL: 'Subtotal',
  SUCCESSFULLY_IMPORTED_CPF: "CPF's importados com sucesso",
  SUM_OF_REFUNDS_GREATER_THAN_THE_TRANSACTION_AMOUNT: 'Soma dos reembolsos maior que o valor da transação.',
  SUM_OF_REFUNDS_GREATER_THAN_THE_VALUE_PROCESSED_AVAILABLE_FOR_REFUND: 'Soma dos reembolsos maior que o valor processado disponível para reembolso.',
  THIS_PRODUCT_ALREDY_INCLUDED: 'Esse produto já foi incluso.',
  THIS_PRODUCT_IS_ALREADY_ON_LIST: 'Esse produto já está na lista.',
  TIME: 'Tempo',
  TITLE_REQUIRED: 'O título é obrigatório',
  TITLE: 'Título',
  TO_ORDER_ITEMS: 'Itens de encomenda',
  TO: 'Até',
  TOKEN_HAS_EXPIRED: 'Sua sessão expirou.',
  TOKEN: 'Token',
  TOTAL_SALES: 'Valor total de vendas',
  TOTAL_SELECTED_ITEMS: '{total} itens selecionados',
  TOTAL: 'Total',
  TRANSACTION_ALREADY_CANCELED: 'Transação já cancelada.',
  TRANSACTION_DOES_NOT_EXIST: 'Transação não encontrada.',
  TRANSACTION_NOT_AVAILABLE_FOR_REFUND_TRY_AGAIN_IN_A_FEW_HOURS: 'Transação não disponível para reembolso. Tente novamente em algumas horas.',
  TRANSACTION_NOT_FOUND: 'Transação não encontrada.',
  TRANSACTION_WITH_PERIOD_EXPIRED_FOR_REFUND: 'Transação com período expirado para reembolso.',
  TRANSACTIONAL_MAILS: 'Emails transacionais',
  TRANSITION_TIME: 'Tempo de transição',
  TYPE_A_WORD: 'Digite uma palavra',
  TYPE_THE_AUTHOR_NAME: 'Digite o nome do autor',
  TYPE_THE_ISBN: 'Digite o ISBN',
  TYPE_THE_PRODUCT_NAME: 'Digite o título do produto',
  TYPE: 'Tipo',
  UNABLE_TO_FIND_FOLLOWING_BARCODES: 'Não foi possível encontrar os seguintes códigos de barras',
  UNAUTHORIZED: 'Permissão negada.',
  UNIT_PRICE: 'Preço unitário',
  UNLIMITED: 'Ilimitado',
  UPDATE_IMAGE: 'Atualizar imagem',
  UPDATED_SUCCESSFULLY: 'Atualizado com sucesso.',
  UPLOAD_LOGO_ERROR: 'Ocorreu um erro ao salvar a imagem. Por favor, tente novamente.',
  URL_FACEBOOK: 'URL Facebook',
  URL_INSTAGRAM: 'URL Instagram',
  USAGE_LIMIT: 'Limite de usos',
  USE_DEFAULT_EMAIL: 'Usar email personalizado',
  USER_DELETED: 'Usuário removido com sucesso.',
  USER_NOT_FOUND: 'Usuário inexistente.',
  USER_ROLE: 'Tipo de usuário',
  USER_SAVED: 'Usuário salvo com sucesso.',
  USER_USAGE_LIMIT: 'Limite de uso por usuário',
  USER: 'Usuário',
  USERS: 'Usuários',
  VALID_UNTIL: 'Válido até',
  VALIDITY: 'Validade',
  VALUE: 'Valor',
  VERIFYING_PAGSEGURO_CONNECTION: 'Verificando a conexão ao PagSeguro',
  VOUCHER: 'Voucher',
  WAITING_WINBOOKS_ORDER: 'Aguardando o envio do pedido',
  WARNING: 'Aviso',
  WEIGHT_EXAMPLE_PLACEHOLDER: 'Exemplo: 0,300 kg',
  WEIGHT_KG: 'Peso (Kg)',
  WEIGHT: 'Peso',
  WHATSAPP: 'WhatsApp',
  WIDTH_CM: 'Largura (cm)',
  WINBOOKS_CREDENTIALS: 'Credenciais WinBooks',
  WINBOOKS_ORDER_DATA: 'Pedido no WinBooks',
  WITHDRAWAL_DEADLINE: 'Prazo para retirada',
  WRONG_CREDENTIALS: 'Senha incorreta.',
  YOU_CANNOT_ADD_MORE_PRODUCTS: 'Você não pode adicionar mais produtos.',
  STYLE_EDITOR: 'Editor de Estilo',
  STYLE_SAVED: 'Estilo salvo com sucesso.',
  STYLE_EMPTY: 'Nenhum CSS foi inserido no Editor de Estilo.',
  STYLE_EDITOR_ERROR_SYNTAX: 'Não foi possível salvar o seu SCSS, verifique seu código antes de salvar.',
  INVALID_CODE_FORMAT: 'O código deve conter apenas números.',
  DESKTOP: 'Desktop',
  MOBILE: 'Mobile',
  RES_DESKTOP: '1920x540',
  RES_MOBILE: '736x339',
  WEBP: 'WEBP',
  JPEG: 'JPEG',
  PNG: 'PNG',
};
